/* .eprescription_print{background-color: #fff;}
.eprescription_print .epres_header{    display: flex !important;  justify-content: space-between !important;   padding: 30px !important;}
.eprescription_print .epres_header .epres_info_left .dr_info{margin-top: 15px !important;}
.eprescription_print .epres_header .epres_info_left .dr_info p{margin-top: 5px !important;}
.eprescription_print .epres_header .epres_info_right p{margin-top: 5px !important;}
.eprescription_print .epres_pate_info {padding: 30px;border-top: 1px solid #ddd;}
.eprescription_print .epres_pate_info .pate__list{display: flex !important;gap: 20px !important;    margin-bottom: 15px !important;}
.eprescription_print .epres_pate_info .pate__list h4{font-size: 35px !important;    width: 40% !important;}
.eprescription_print .epres_pate_info .pate__list p{    width: 60% !important;}
.eprescription_print .epres_body{padding: 30px !important;border-top: 1px solid #ddd !important;}
.eprescription_print .epres_body .ep_details{margin-bottom: 50px !important;}
.eprescription_print .epres_body .ep_details h4{}
.ep_details .ep_medicine{    margin-left: 30px !important;    margin-top: 20px !important;}
.ep_details .ep_medicine p{}

.epres_body .dr_signtext{  text-align: center !important; margin-top: 0px !important;}
.epres_body .dr_sign{ text-align: center !important; margin-top: 50px !important; } */



.eprescription_print .epres_footer{padding: 20px 20px !important;text-align: center !important;border-top: 1px solid #ddd !important;}